.status-embed {
  margin-bottom: 20px;
}

.status-embed .config-display-columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.status-embed .config-display-columns .config-column {
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
}

.status-embed .config-display-columns .display-column {
  margin-left: 40px;
  -webkit-box-flex: 3;
      -ms-flex: 3;
          flex: 3;
}

.status-embed .config-display-columns .color-examples {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
}

@media only screen and (max-width: 600px) {
  .status-embed .config-display-columns {
    display: inline-block;
  }

  .status-embed .config-display-columns .display-column {
    margin-left: 0;
    width: 100%;
  }

  .status-embed .color-examples {
    padding: 10px 0 !important;
  }
}

.status-embed .description {
  margin-bottom: 11px;
}

.status-embed .status-message {
  background-color: #e3fcef;
  padding: 16px;
  border-radius: 2px;
  position: relative;
}

.status-embed .status-message p {
  margin-bottom: 0;
}

.status-embed .status-message.offline {
  background-color: #deebff;
}

.status-embed .status-message .icon-container {
  color: #064;
  position: absolute;
  top: 16px;
}

.status-embed .status-message .icon-container.offline {
  color: #0747a6;
}

.status-embed .status-message .message-container {
  margin-left: 40px;
  display: inline-block;
}

.status-embed .position-setup {
  margin-top: 27px;
}

@media only screen and (max-width: 600px) {
  .status-embed .position-setup {
    margin-top: 36px;
  }
}

.status-embed .position-setup .position-config h1 {
  font-size: 16px;
  color: #253858;
  margin: 0;
  line-height: 16px;
}

.status-embed .position-setup .position-config .status-radio-group {
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .status-embed .position-setup .position-config .status-radio-group {
    margin-top: 11px;
  }
}

@media only screen and (max-width: 600px) {
  .status-embed .position-setup .position-preview {
    margin-top: 15px;
  }
}

.status-embed .position-setup .position-preview .img-container>img {
  width: 100%;
}

.status-embed .position-setup .position-preview .preview-app-position {
  position: relative;
}

.status-embed .position-setup .position-preview .preview-status-embed-position {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 122px;
  -webkit-transition: bottom .5s ease,right .5s ease;
  transition: bottom .5s ease,right .5s ease;
}

.status-embed .position-setup .position-preview .preview-status-embed-position.bottom-left {
  bottom: 16px;
  right: calc(100% - 140px);
}

.status-embed .position-setup .position-preview .preview-status-embed-position.bottom-right {
  bottom: 16px;
  right: 18px;
}

.status-embed .position-setup .position-preview .preview-status-embed-position.top-right {
  bottom: calc(100% - 76px);
  right: 18px;
}

.status-embed .position-setup .position-preview .app-preview-note {
  color: #5e6c84;
  font-size: 12px;
  margin-top: 10px;
  margin-right: 8px;
  line-height: 16px;
}

.status-embed .color-setup .title {
  margin-top: 2px;
  margin-bottom: 9px;
  font-weight: 600;
  font-size: 16px;
}

@media only screen and (max-width: 600px) {
  .status-embed .color-setup .title {
    margin-top: 27px;
  }
}

.status-embed .color-setup .control-group {
  margin-top: 0px;
}

@media only screen and (max-width: 600px) {
  .status-embed .color-setup .control-group {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.status-embed .color-setup .color-group {
  margin-top: 20px;
  margin-bottom: 48px;
}

@media only screen and (max-width: 600px) {
  .status-embed .color-setup .color-group {
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.status-embed .color-setup .color-input-container {
  position: flex;
}

.status-embed .color-setup .color-input-container::before {
  content: "#";
  position: absolute;
  display: inline-block;
  font-size: 16px;
  line-height: 40px;
  margin-left: 10px;
  color: #666;
}

.status-embed .color-setup .color-input-container input {
  padding-left: 19px;
}

.status-embed .color-setup .color-input-container .invalid-color {
  position: absolute;
  color: #de350b;
  font-size: 12px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.status-embed .color-setup .color-input-container .invalid-color .error-text {
  margin: 1px 0 0 0;
}

.status-embed .color-setup iframe {
  margin-top: 0;
  width: 320px;
  height: 115px;
  border-radius: 4px;
  -webkit-box-shadow: rgba(9, 30, 66, .31) 0px 0px 1px,rgba(9, 30, 66, .25) 0px 20px 32px -8px;
          box-shadow: rgba(9, 30, 66, .31) 0px 0px 1px,rgba(9, 30, 66, .25) 0px 20px 32px -8px;
}

@media only screen and (max-width: 600px) {
  .status-embed .color-setup iframe {
    margin-top: 16px;
    width: inherit;
  }
}

@media only screen and (max-width: 600px) {
  .status-embed .color-setup .maintenance .color-choices {
    margin-top: 45px;
    margin-bottom: 48px;
  }
}

@media only screen and (max-width: 600px)and (max-width: 600px) {
  .status-embed .color-setup .maintenance .color-choices {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 600px) {
  .status-embed .color-setup .control-group {
    margin-top: 11px;
  }
}

.status-embed .color-setup .control-group input {
  width: 197px;
}

@media only screen and (max-width: 600px) {
  .status-embed .color-setup .control-group input {
    width: 100%;
  }
}

.status-embed .color-setup .control-group:nth-child(2) {
  margin-top: 37px;
}

.status-embed .embed-form-controls {
  float: right;
  margin-bottom: 118px;
}

@media only screen and (max-width: 600px) {
  .status-embed .embed-form-controls {
    margin-top: 43px;
    margin-bottom: 64px;
  }
}

@media only screen and (max-width: 600px) {
  .status-embed .embed-form-controls {
    margin-top: 43px;
    margin-bottom: 64px;
  }
}

.status-embed .embed-form-controls button {
  margin-left: 8px;
  margin-left: .5rem;
}

.status-embed .embed-form-controls .copy-code-btn {
  color: #0052cc;
}

.status-embed .embed-form-controls .copy-code-btn.not-adg3 {
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  font-size: .875rem;
  display: inline-block;
  margin-right: 8px;
  margin-right: .5rem;
}

.status-embed .embed-code-modal .title {
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 5px;
}

.status-embed .embed-code-modal .code {
  border-radius: 3px;
  background: #f4f5f7;
  color: #172b4d;
  padding: 2px 4px;
}

.status-embed .embed-code-modal #copy-icon {
  margin-right: 40px;
}

.status-embed .embed-code-modal #copy-icon a {
  margin-left: 10px;
  text-decoration: none;
  color: #172b4d;
  position: absolute;
  cursor: pointer;
}

.status-embed .embed-code-modal #copy-icon a i {
  position: absolute;
  font-size: 24px;
}

.status-embed .embed-code-modal #code-copied {
  display: inline-block;
  text-align: center;
  font-size: 12px;
  height: 22px;
  width: 88px;
  color: #fff;
  background-color: #172b4d;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .2);
          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .2);
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
}

.status-embed .embed-code-modal #code-copied.visible {
  visibility: visible;
  opacity: 1;
}

.status-embed .embed-code-modal #code-copied.faded {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 1s linear,visibility 0s linear 1s;
  transition: opacity 1s linear,visibility 0s linear 1s;
}

.status-embed .embed-code-modal .embed-code-block {
  width: 100%;
  background: #253858;
  overflow: scroll;
  color: #fff;
  padding: 8px 20px;
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  overflow: hidden;
}

.status-embed .embed-code-modal .confirm-btn {
  float: right;
}

.status-embed .embed-code-modal .modal-header {
  padding-bottom: 0px;
}

@media only screen and (max-width: 600px) {
  .status-embed .embed-code-modal .modal-body {
    padding-top: 0;
    margin-top: 16px;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
  }

  .status-embed .embed-code-modal .embed-code-block {
    margin-top: 12px;
    word-break: break-all;
  }
}

