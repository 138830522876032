@media screen and (max-width: 485px) {
  .activate-audience-specific .price-explanation-group {
    margin: 0 68px;
  }
}

.activate-audience-specific .description {
  padding-bottom: 54px;
}

.activate-audience-specific .description ul {
  padding-top: 15px;
}

.activate-audience-specific .description li {
  list-style: disc inside;
  font-size: 25px;
}

.activate-audience-specific .description li span {
  font-size: 17px;
  position: relative;
  margin-left: -19px;
}

.activate-audience-specific .description h4 {
  font-size: 22px;
}

.activate-audience-specific .fa-warning {
  color: #ff8b00;
}

.activate-audience-specific .fa-info-circle {
  color: #42526e;
}

.activate-audience-specific .pricing-tier-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 17px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.activate-audience-specific .pricing-tier-row .pricing-tier-label {
  color: #333;
  font-size: 26px;
  font-weight: 500;
  padding-right: 14px;
  text-align: center;
  padding-top: 16px;
}

.activate-audience-specific .pricing-tier-row .select-container {
  width: 69px;
  height: 40px;
}

.activate-audience-specific .warning-container {
  max-width: 350px;
  background-color: #fffae6;
  margin: 0 auto 11px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.activate-audience-specific .warning-container .icon {
  padding: 16px 16px 37px;
}

.activate-audience-specific .warning-container .message {
  text-align: left;
}

.activate-audience-specific .plan-detail-selector {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
}

.activate-audience-specific .plan-detail {
  -ms-flex-preferred-size: 350px;
      flex-basis: 350px;
  height: 354px;
  float: left;
  border: 1px solid #dfe1e6;
  border-radius: 2px;
}

.activate-audience-specific .plan-detail .lozenge {
  margin: 16px auto 8px;
}

.activate-audience-specific .plan-detail .lozenge.invisible {
  visibility: hidden;
}

.activate-audience-specific .plan-detail.with-lozenge {
  height: 370px;
}

.activate-audience-specific .plan-detail .header {
  margin: 33.6px 24px 19.2px;
}

.activate-audience-specific .plan-detail .header.with-lozenge {
  margin-top: 0;
}

.activate-audience-specific .plan-detail .header .price {
  font-size: 54.4px;
  line-height: 55.488px;
}

.activate-audience-specific .plan-detail .header .price .sup,
.activate-audience-specific .plan-detail .header .price .sub {
  font-size: 16px;
  line-height: 24px;
  position: relative;
}

.activate-audience-specific .plan-detail .header .price .sup {
  top: -26px;
}

.activate-audience-specific .plan-detail .header .price .amount {
  font-weight: 500;
}

.activate-audience-specific .plan-detail .header .name {
  margin-top: 8px;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 2px;
}

.activate-audience-specific .plan-detail .table-row {
  margin-bottom: 8px;
  color: #172b4d;
}

.activate-audience-specific .plan-detail .table-row.bigger {
  font-size: 21.2px;
  line-height: 30.422px;
}

.activate-audience-specific .plan-detail .table-row.bigger .more-info {
  margin-top: 1px;
}

.activate-audience-specific .plan-detail .table-row.smaller {
  font-size: 15.6px;
  line-height: 23.478px;
}

.activate-audience-specific .plan-detail .table-row .more-info {
  position: absolute;
}

.activate-audience-specific .plan-detail .table-row .info-tooltip {
  z-index: -1;
}

.activate-audience-specific .plan-detail .button-container {
  margin-bottom: 40px;
}

.activate-audience-specific .plan-detail ul {
  display: inline-block;
  margin: auto;
  text-align: left;
  padding-top: 10px;
}

.activate-audience-specific .plan-detail ul.with-lozenge {
  padding-bottom: 32px;
}

.activate-audience-specific .price-explanation {
  text-align: center;
  cursor: pointer;
  padding: 32px;
}

.activate-audience-specific .price-explanation .chevron {
  position: absolute;
  margin-top: -5px;
}

.activate-audience-specific .price-explanation.invisible {
  visibility: hidden;
}

.activate-audience-specific .pricing-table {
  border-collapse: collapse;
  margin-top: 30px;
}

.activate-audience-specific .pricing-table th {
  border-bottom: 1px solid #ddd;
  color: #6b778c;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
}

.activate-audience-specific .pricing-table th,
.activate-audience-specific .pricing-table td {
  padding-right: 92px;
}

.activate-audience-specific .pricing-table td {
  padding-top: 8px;
  font-size: 17px;
}

