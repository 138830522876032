.modal-import-users {
  width: 536px;
  padding: 32px 24px 24px;
}

.modal-import-users .content h3 {
  margin: 0;
  font-size: 21px;
}

.modal-import-users .content .header {
  margin-bottom: 30px;
}

.modal-import-users .content .header h3.modal-title {
  margin-bottom: 8px;
}

.modal-import-users .content .body {
  font-weight: 500;
  margin-bottom: 30px;
}

.modal-import-users .content .body ol {
  margin-left: 17px;
}

.modal-import-users .content .body li {
  margin-bottom: 8px;
}

.modal-import-users .content .body li small.footnote {
  color: #6b778c;
  margin-top: 2px;
}

.modal-import-users .content .body a {
  font-weight: 500;
}

.modal-import-users .content .modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 4px 0;
}

.modal-import-users .content .modal-footer div {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.modal-import-users .content .modal-footer a,
.modal-import-users .content .modal-footer button {
  font-weight: 500;
}

.modal-import-users .content .modal-footer .learn-more {
  text-align: left;
}

.modal-import-users .content .modal-footer .learn-more a {
  margin-top: 4px;
}

.modal-import-users .disabled-warning {
  margin: -6px 0 24px;
}

.modal-import-users .file-upload-container {
  margin-bottom: 40px;
  height: 62px;
  width: 100%;
  border: 1px solid #c1c7d0;
  border-radius: 3px;
}

.modal-import-users .file-upload-container input[type=file] {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.modal-import-users .file-upload-container label,
.modal-import-users .file-upload-container button.disabled {
  display: inline-block;
  position: relative;
  margin: 14px;
}

.modal-import-users .file-upload-container label {
  background-color: #f5f6f8;
  border-radius: 3px;
  line-height: 31px;
  height: 34px;
  width: 97px;
  text-align: center;
  cursor: pointer;
}

