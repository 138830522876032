.page-authentication .heading .title {
  font-size: 16px;
  font-weight: 600;
  margin-top: 50px;
  margin-bottom: 35px;
}

.page-authentication .config-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 25px 0;
  border-bottom: 1px solid #dfe1e6;
}

.page-authentication .config-row .description .main {
  font-size: 16px;
  font-weight: 600;
}

.page-authentication .configure-step.step-2 {
  margin-top: 32px;
}

.page-authentication .configure-step .title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 25px;
}

.page-authentication .configure-step .field-container {
  margin-bottom: 15px;
}

.page-authentication .configure-step .field-container .label {
  color: #6b778c;
  font-size: 12px;
  font-weight: 600;
}

.page-authentication .configure-step .field-container .label .tooltip-base {
  top: 1px;
}

.page-authentication .configure-step .subsection {
  margin-top: 10px;
}

.page-authentication .subtext,
.page-authentication .subtext a {
  font-size: 11px;
}

.page-authentication .subtext {
  color: #6b778c;
}

.page-authentication textarea {
  height: 145px;
}

.page-authentication .footer {
  margin-top: 20px;
}

.page-authentication .footer.two-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.page-authentication .footer .form-actions-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.page-authentication .footer .form-actions-container button {
  margin-left: 15px;
}

.page-authentication .previous-page-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  color: #172b4d;
}

.page-authentication .previous-page-link:hover {
  text-decoration: none;
}

.page-authentication .previous-page-link .chevron-container {
  margin-right: 2px;
}

.page-authentication .previous-page-link .text {
  margin-top: -2px;
}

.page-authentication .btn-delete-config,
.page-authentication .btn-delete-config:hover {
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  color: #42525e;
}

.page-authentication .legacy-options {
  margin-bottom: 32px;
}

.page-authentication .legacy-option-container {
  padding: 25px 0;
}

.page-authentication .disable-message {
  color: #6b778c;
  font-size: 11px;
}

