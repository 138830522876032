.subscriber-heading .border {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  text-align: center;
  padding: 25.6px;
  padding: 1.6rem;
  margin-bottom: 48px;
  margin-bottom: 3rem;
}

.subscriber-heading .mode {
  text-align: center;
  width: 100%;
  font-size: 12.8px;
  font-size: .8rem;
  line-height: 16px;
  line-height: 1rem;
  margin-bottom: 0;
}

.subscriber-heading .identifier {
  text-align: center;
  width: 100%;
  font-size: 22.4px !important;
  font-size: 1.4rem !important;
  margin-bottom: 6.4px !important;
  margin-bottom: .4rem !important;
  word-wrap: break-word;
}

.subscriber-heading .unsubscribe-button {
  width: auto;
  margin: 0 auto;
  border: none;
  padding: 0;
  background: none;
  font-size: 12.8px;
  font-size: .8rem;
}

.subscriber-heading .unsubscribe-button:hover {
  text-decoration: underline;
}

.subscriber-heading .unsubscribe-button:active {
  color: #cb5757;
}

.new-subscription .heading .title {
  margin-bottom: 4.8px;
  margin-bottom: .3rem;
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 500;
}

.new-subscription .heading .subscriber {
  font-size: 14px;
  font-size: .875rem;
  font-weight: 500;
}

.new-subscription .heading .instructions {
  margin-top: 18px;
  margin-top: 1.125rem;
  margin-bottom: 23.04px;
  margin-bottom: 1.44rem;
  font-size: 14px;
  font-size: .875rem;
}

.new-subscription .global-toggle-btn {
  cursor: pointer;
  margin-left: 11.2px;
  margin-left: .7rem;
  font-size: 14px;
  font-size: .875rem;
  font-weight: 500;
}

.component-form .header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.component-form #component-selector-toggle {
  cursor: pointer;
}

.component-form .form-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.component-form .cancel-btn {
  padding-right: 10px;
  padding-top: 5px;
}

.component-form .flat-button.disabled {
  opacity: .5;
  cursor: not-allowed;
}

